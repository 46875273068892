'use client';

import { useEffect } from 'react';
import markerSDK from '@marker.io/browser';
import { initializeLocation } from '@/helpers/mistService';

/*
 * GlobalFunctions - Client component that executes specific functions
 */

const GlobalFunctions = () => {
	useEffect(() => {
		initializeLocation();

		// show marker.io widget in Amplify preview and staging environments
		if (
			process.env.NEXT_PUBLIC_ENVIRONMENT === 'preview' ||
			process.env.NEXT_PUBLIC_ENVIRONMENT === 'stg'
		) {
			initializeMarker();
		}
	}, []);

	function initializeMarker() {
		markerSDK.loadWidget({
			project: '6512fcefab54f40fb4375c04',
		});
		return null;
	}

	return null;
};

export default GlobalFunctions;
