'use client';

import Script from 'next/script';

export interface GoogleTagManagerScriptProps {
	testId?: string;
}

const GoogleTagManagerScript = (props: GoogleTagManagerScriptProps) => {
	const { testId = 'GoogleTagManagerScript' } = props;

	// Don't load GTM during local development
	if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'dev') return null;

	const isMtechUser =
		typeof window !== 'undefined' && localStorage.getItem('isMtechUser');

	if (isMtechUser === 'yes') return null;

	return (
		<Script id="gtm-script" data-testid={testId}>
			{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
  var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NBB89M4');
  `}
		</Script>
	);
};

export default GoogleTagManagerScript;
