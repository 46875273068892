'use client';

import { useEffect, useRef } from 'react';
import profiler from '@/helpers/userProfile';

const HeapData = () => {
	const identified = useRef(false);
	const count = useRef(0);
	const [userProfile] = profiler();

	useEffect(() => {
		const identify = () => {
			if (window.heap && !identified.current) {
				const id = userProfile?.mtaAid;
				window.heap.addUserProperties({
					MTAAnonymousID: id,
				});
				window.heap.identify(id);

				if (id) {
					identified.current = true;
				}
			}

			if (!identified.current && count.current++ < 10) {
				setTimeout(identify, 100);
			}
		};

		identify();
	}, [userProfile?.mtaAid]);

	return null;
};

export default HeapData;
